exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-adminpanel-js": () => import("./../../../src/pages/adminpanel.js" /* webpackChunkName: "component---src-pages-adminpanel-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blogpost-2-js": () => import("./../../../src/pages/blogpost2.js" /* webpackChunkName: "component---src-pages-blogpost-2-js" */),
  "component---src-pages-blogpost-3-js": () => import("./../../../src/pages/blogpost3.js" /* webpackChunkName: "component---src-pages-blogpost-3-js" */),
  "component---src-pages-blogpost-4-js": () => import("./../../../src/pages/blogpost4.js" /* webpackChunkName: "component---src-pages-blogpost-4-js" */),
  "component---src-pages-blogpost-js": () => import("./../../../src/pages/blogpost.js" /* webpackChunkName: "component---src-pages-blogpost-js" */),
  "component---src-pages-comingsoon-js": () => import("./../../../src/pages/comingsoon.js" /* webpackChunkName: "component---src-pages-comingsoon-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lease-success-js": () => import("./../../../src/pages/leaseSuccess.js" /* webpackChunkName: "component---src-pages-lease-success-js" */),
  "component---src-pages-locations-js": () => import("./../../../src/pages/locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-observatory-js": () => import("./../../../src/pages/observatory.js" /* webpackChunkName: "component---src-pages-observatory-js" */),
  "component---src-pages-observatory-nares-js": () => import("./../../../src/pages/observatory-nares.js" /* webpackChunkName: "component---src-pages-observatory-nares-js" */),
  "component---src-pages-observatory-scott-js": () => import("./../../../src/pages/observatory-scott.js" /* webpackChunkName: "component---src-pages-observatory-scott-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-payment-success-js": () => import("./../../../src/pages/paymentSuccess.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-rondebosch-js": () => import("./../../../src/pages/rondebosch.js" /* webpackChunkName: "component---src-pages-rondebosch-js" */),
  "component---src-pages-roomselector-js": () => import("./../../../src/pages/roomselector.js" /* webpackChunkName: "component---src-pages-roomselector-js" */),
  "component---src-pages-roomselector-nares-js": () => import("./../../../src/pages/roomselector-nares.js" /* webpackChunkName: "component---src-pages-roomselector-nares-js" */),
  "component---src-pages-roomselector-nursery-js": () => import("./../../../src/pages/roomselector-nursery.js" /* webpackChunkName: "component---src-pages-roomselector-nursery-js" */),
  "component---src-pages-roomselector-scott-js": () => import("./../../../src/pages/roomselector-scott.js" /* webpackChunkName: "component---src-pages-roomselector-scott-js" */),
  "component---src-pages-roomselector-shelley-js": () => import("./../../../src/pages/roomselector-shelley.js" /* webpackChunkName: "component---src-pages-roomselector-shelley-js" */),
  "component---src-pages-roomselector-william-js": () => import("./../../../src/pages/roomselector-william.js" /* webpackChunkName: "component---src-pages-roomselector-william-js" */),
  "component---src-pages-saltriver-shelley-js": () => import("./../../../src/pages/saltriver-shelley.js" /* webpackChunkName: "component---src-pages-saltriver-shelley-js" */),
  "component---src-pages-signing-js": () => import("./../../../src/pages/signing.js" /* webpackChunkName: "component---src-pages-signing-js" */),
  "component---src-pages-spaces-js": () => import("./../../../src/pages/spaces.js" /* webpackChunkName: "component---src-pages-spaces-js" */),
  "component---src-pages-woodstock-js": () => import("./../../../src/pages/woodstock.js" /* webpackChunkName: "component---src-pages-woodstock-js" */)
}

